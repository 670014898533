export const Banner = () => import('../../components/banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const AsideCard = () => import('../../components/cards/AsideCard.vue' /* webpackChunkName: "components/aside-card" */).then(c => wrapFunctional(c.default || c))
export const CampaignStoryCard = () => import('../../components/cards/CampaignStoryCard.vue' /* webpackChunkName: "components/campaign-story-card" */).then(c => wrapFunctional(c.default || c))
export const DefaultCard = () => import('../../components/cards/DefaultCard.vue' /* webpackChunkName: "components/default-card" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/cards/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const MemberCard = () => import('../../components/cards/MemberCard.vue' /* webpackChunkName: "components/member-card" */).then(c => wrapFunctional(c.default || c))
export const NewsCardLarge = () => import('../../components/cards/NewsCardLarge.vue' /* webpackChunkName: "components/news-card-large" */).then(c => wrapFunctional(c.default || c))
export const PublicationCard = () => import('../../components/cards/PublicationCard.vue' /* webpackChunkName: "components/publication-card" */).then(c => wrapFunctional(c.default || c))
export const RepresentativesCard = () => import('../../components/cards/RepresentativesCard.vue' /* webpackChunkName: "components/representatives-card" */).then(c => wrapFunctional(c.default || c))
export const TextCard = () => import('../../components/cards/TextCard.vue' /* webpackChunkName: "components/text-card" */).then(c => wrapFunctional(c.default || c))
export const ThemeTextCard = () => import('../../components/cards/ThemeTextCard.vue' /* webpackChunkName: "components/theme-text-card" */).then(c => wrapFunctional(c.default || c))
export const VisualCard = () => import('../../components/cards/VisualCard.vue' /* webpackChunkName: "components/visual-card" */).then(c => wrapFunctional(c.default || c))
export const HorizontalScroller = () => import('../../components/animation/HorizontalScroller.vue' /* webpackChunkName: "components/horizontal-scroller" */).then(c => wrapFunctional(c.default || c))
export const ImageSequence = () => import('../../components/animation/ImageSequence.vue' /* webpackChunkName: "components/image-sequence" */).then(c => wrapFunctional(c.default || c))
export const IconButton = () => import('../../components/buttons/IconButton.vue' /* webpackChunkName: "components/icon-button" */).then(c => wrapFunctional(c.default || c))
export const PillButton = () => import('../../components/buttons/PillButton.vue' /* webpackChunkName: "components/pill-button" */).then(c => wrapFunctional(c.default || c))
export const SubmitButton = () => import('../../components/buttons/SubmitButton.vue' /* webpackChunkName: "components/submit-button" */).then(c => wrapFunctional(c.default || c))
export const Tag = () => import('../../components/buttons/Tag.vue' /* webpackChunkName: "components/tag" */).then(c => wrapFunctional(c.default || c))
export const CampaignIntro = () => import('../../components/campaigns/CampaignIntro.vue' /* webpackChunkName: "components/campaign-intro" */).then(c => wrapFunctional(c.default || c))
export const CampaignLargeVisual = () => import('../../components/campaigns/CampaignLargeVisual.vue' /* webpackChunkName: "components/campaign-large-visual" */).then(c => wrapFunctional(c.default || c))
export const CampaignOtherStories = () => import('../../components/campaigns/CampaignOtherStories.vue' /* webpackChunkName: "components/campaign-other-stories" */).then(c => wrapFunctional(c.default || c))
export const CampaignQuote = () => import('../../components/campaigns/CampaignQuote.vue' /* webpackChunkName: "components/campaign-quote" */).then(c => wrapFunctional(c.default || c))
export const CampaignStackedVisuals = () => import('../../components/campaigns/CampaignStackedVisuals.vue' /* webpackChunkName: "components/campaign-stacked-visuals" */).then(c => wrapFunctional(c.default || c))
export const CampaignText = () => import('../../components/campaigns/CampaignText.vue' /* webpackChunkName: "components/campaign-text" */).then(c => wrapFunctional(c.default || c))
export const CampaignVisual = () => import('../../components/campaigns/CampaignVisual.vue' /* webpackChunkName: "components/campaign-visual" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterBlock = () => import('../../components/footer/FooterBlock.vue' /* webpackChunkName: "components/footer-block" */).then(c => wrapFunctional(c.default || c))
export const FooterDefault = () => import('../../components/footer/FooterDefault.vue' /* webpackChunkName: "components/footer-default" */).then(c => wrapFunctional(c.default || c))
export const FooterLinksMVM = () => import('../../components/footer/FooterLinksMVM.vue' /* webpackChunkName: "components/footer-links-m-v-m" */).then(c => wrapFunctional(c.default || c))
export const FooterMVM = () => import('../../components/footer/FooterMVM.vue' /* webpackChunkName: "components/footer-m-v-m" */).then(c => wrapFunctional(c.default || c))
export const DownloadPublicationForm = () => import('../../components/forms/DownloadPublicationForm.vue' /* webpackChunkName: "components/download-publication-form" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribeForm = () => import('../../components/forms/NewsletterSubscribeForm.vue' /* webpackChunkName: "components/newsletter-subscribe-form" */).then(c => wrapFunctional(c.default || c))
export const ArticleHeader = () => import('../../components/headers/ArticleHeader.vue' /* webpackChunkName: "components/article-header" */).then(c => wrapFunctional(c.default || c))
export const HomeHeader = () => import('../../components/headers/HomeHeader.vue' /* webpackChunkName: "components/home-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/headers/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const HomeHighlight = () => import('../../components/home/HomeHighlight.vue' /* webpackChunkName: "components/home-highlight" */).then(c => wrapFunctional(c.default || c))
export const Arrow = () => import('../../components/icons/Arrow.vue' /* webpackChunkName: "components/arrow" */).then(c => wrapFunctional(c.default || c))
export const BaseShape = () => import('../../components/icons/BaseShape.vue' /* webpackChunkName: "components/base-shape" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/icons/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const Shape = () => import('../../components/icons/Shape.vue' /* webpackChunkName: "components/shape" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/layout/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const Module = () => import('../../components/layout/Module.vue' /* webpackChunkName: "components/module" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/layout/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const CustomLink = () => import('../../components/links/CustomLink.vue' /* webpackChunkName: "components/custom-link" */).then(c => wrapFunctional(c.default || c))
export const EventsSearchResult = () => import('../../components/links/EventsSearchResult.vue' /* webpackChunkName: "components/events-search-result" */).then(c => wrapFunctional(c.default || c))
export const PublicationSearchResult = () => import('../../components/links/PublicationSearchResult.vue' /* webpackChunkName: "components/publication-search-result" */).then(c => wrapFunctional(c.default || c))
export const RegularSearchResult = () => import('../../components/links/RegularSearchResult.vue' /* webpackChunkName: "components/regular-search-result" */).then(c => wrapFunctional(c.default || c))
export const StaticVideo = () => import('../../components/media/StaticVideo.vue' /* webpackChunkName: "components/static-video" */).then(c => wrapFunctional(c.default || c))
export const VImage = () => import('../../components/media/VImage.vue' /* webpackChunkName: "components/v-image" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/media/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const Visual = () => import('../../components/media/Visual.vue' /* webpackChunkName: "components/visual" */).then(c => wrapFunctional(c.default || c))
export const VisualCredits = () => import('../../components/media/VisualCredits.vue' /* webpackChunkName: "components/visual-credits" */).then(c => wrapFunctional(c.default || c))
export const About = () => import('../../components/modules/About.vue' /* webpackChunkName: "components/about" */).then(c => wrapFunctional(c.default || c))
export const ArticlesOverview = () => import('../../components/modules/ArticlesOverview.vue' /* webpackChunkName: "components/articles-overview" */).then(c => wrapFunctional(c.default || c))
export const Buttons = () => import('../../components/modules/Buttons.vue' /* webpackChunkName: "components/buttons" */).then(c => wrapFunctional(c.default || c))
export const CampaignStories = () => import('../../components/modules/CampaignStories.vue' /* webpackChunkName: "components/campaign-stories" */).then(c => wrapFunctional(c.default || c))
export const Columns = () => import('../../components/modules/Columns.vue' /* webpackChunkName: "components/columns" */).then(c => wrapFunctional(c.default || c))
export const Contact = () => import('../../components/modules/Contact.vue' /* webpackChunkName: "components/contact" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../components/modules/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const Download = () => import('../../components/modules/Download.vue' /* webpackChunkName: "components/download" */).then(c => wrapFunctional(c.default || c))
export const EventsOverview = () => import('../../components/modules/EventsOverview.vue' /* webpackChunkName: "components/events-overview" */).then(c => wrapFunctional(c.default || c))
export const FunctionProfilesOverview = () => import('../../components/modules/FunctionProfilesOverview.vue' /* webpackChunkName: "components/function-profiles-overview" */).then(c => wrapFunctional(c.default || c))
export const ImageSlider = () => import('../../components/modules/ImageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c))
export const ImageWithText = () => import('../../components/modules/ImageWithText.vue' /* webpackChunkName: "components/image-with-text" */).then(c => wrapFunctional(c.default || c))
export const InformationBanner = () => import('../../components/modules/InformationBanner.vue' /* webpackChunkName: "components/information-banner" */).then(c => wrapFunctional(c.default || c))
export const MembersOverview = () => import('../../components/modules/MembersOverview.vue' /* webpackChunkName: "components/members-overview" */).then(c => wrapFunctional(c.default || c))
export const NewsOverview = () => import('../../components/modules/NewsOverview.vue' /* webpackChunkName: "components/news-overview" */).then(c => wrapFunctional(c.default || c))
export const NewsRelated = () => import('../../components/modules/NewsRelated.vue' /* webpackChunkName: "components/news-related" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSubscribe = () => import('../../components/modules/NewsletterSubscribe.vue' /* webpackChunkName: "components/newsletter-subscribe" */).then(c => wrapFunctional(c.default || c))
export const NextFunctionProfiles = () => import('../../components/modules/NextFunctionProfiles.vue' /* webpackChunkName: "components/next-function-profiles" */).then(c => wrapFunctional(c.default || c))
export const PatientStories = () => import('../../components/modules/PatientStories.vue' /* webpackChunkName: "components/patient-stories" */).then(c => wrapFunctional(c.default || c))
export const PatientStoriesOverview = () => import('../../components/modules/PatientStoriesOverview.vue' /* webpackChunkName: "components/patient-stories-overview" */).then(c => wrapFunctional(c.default || c))
export const Publication = () => import('../../components/modules/Publication.vue' /* webpackChunkName: "components/publication" */).then(c => wrapFunctional(c.default || c))
export const PublicationFilterGroup = () => import('../../components/modules/PublicationFilterGroup.vue' /* webpackChunkName: "components/publication-filter-group" */).then(c => wrapFunctional(c.default || c))
export const PublicationFilters = () => import('../../components/modules/PublicationFilters.vue' /* webpackChunkName: "components/publication-filters" */).then(c => wrapFunctional(c.default || c))
export const PublicationsFeatured = () => import('../../components/modules/PublicationsFeatured.vue' /* webpackChunkName: "components/publications-featured" */).then(c => wrapFunctional(c.default || c))
export const PublicationsOverview = () => import('../../components/modules/PublicationsOverview.vue' /* webpackChunkName: "components/publications-overview" */).then(c => wrapFunctional(c.default || c))
export const Quote = () => import('../../components/modules/Quote.vue' /* webpackChunkName: "components/quote" */).then(c => wrapFunctional(c.default || c))
export const RepresentativesOverview = () => import('../../components/modules/RepresentativesOverview.vue' /* webpackChunkName: "components/representatives-overview" */).then(c => wrapFunctional(c.default || c))
export const RichTextModule = () => import('../../components/modules/RichTextModule.vue' /* webpackChunkName: "components/rich-text-module" */).then(c => wrapFunctional(c.default || c))
export const SuggestedStories = () => import('../../components/modules/SuggestedStories.vue' /* webpackChunkName: "components/suggested-stories" */).then(c => wrapFunctional(c.default || c))
export const Themes = () => import('../../components/modules/Themes.vue' /* webpackChunkName: "components/themes" */).then(c => wrapFunctional(c.default || c))
export const ThemesOverview = () => import('../../components/modules/ThemesOverview.vue' /* webpackChunkName: "components/themes-overview" */).then(c => wrapFunctional(c.default || c))
export const Tiles = () => import('../../components/modules/Tiles.vue' /* webpackChunkName: "components/tiles" */).then(c => wrapFunctional(c.default || c))
export const VacanciesOverview = () => import('../../components/modules/VacanciesOverview.vue' /* webpackChunkName: "components/vacancies-overview" */).then(c => wrapFunctional(c.default || c))
export const VisualModule = () => import('../../components/modules/VisualModule.vue' /* webpackChunkName: "components/visual-module" */).then(c => wrapFunctional(c.default || c))
export const YTEmbed = () => import('../../components/modules/YTEmbed.vue' /* webpackChunkName: "components/y-t-embed" */).then(c => wrapFunctional(c.default || c))
export const DesktopSubNav = () => import('../../components/nav/DesktopSubNav.vue' /* webpackChunkName: "components/desktop-sub-nav" */).then(c => wrapFunctional(c.default || c))
export const IconBox = () => import('../../components/nav/IconBox.vue' /* webpackChunkName: "components/icon-box" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/nav/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MobileNav = () => import('../../components/nav/MobileNav.vue' /* webpackChunkName: "components/mobile-nav" */).then(c => wrapFunctional(c.default || c))
export const Nav = () => import('../../components/nav/Nav.vue' /* webpackChunkName: "components/nav" */).then(c => wrapFunctional(c.default || c))
export const NavLinks = () => import('../../components/nav/NavLinks.vue' /* webpackChunkName: "components/nav-links" */).then(c => wrapFunctional(c.default || c))
export const NavLinksSub = () => import('../../components/nav/NavLinksSub.vue' /* webpackChunkName: "components/nav-links-sub" */).then(c => wrapFunctional(c.default || c))
export const NavSocialMedia = () => import('../../components/nav/NavSocialMedia.vue' /* webpackChunkName: "components/nav-social-media" */).then(c => wrapFunctional(c.default || c))
export const ParentLogo = () => import('../../components/nav/ParentLogo.vue' /* webpackChunkName: "components/parent-logo" */).then(c => wrapFunctional(c.default || c))
export const SearchBox = () => import('../../components/nav/SearchBox.vue' /* webpackChunkName: "components/search-box" */).then(c => wrapFunctional(c.default || c))
export const Documents = () => import('../../components/news/Documents.vue' /* webpackChunkName: "components/documents" */).then(c => wrapFunctional(c.default || c))
export const NewsFilter = () => import('../../components/news/NewsFilter.vue' /* webpackChunkName: "components/news-filter" */).then(c => wrapFunctional(c.default || c))
export const NewsGrid = () => import('../../components/news/NewsGrid.vue' /* webpackChunkName: "components/news-grid" */).then(c => wrapFunctional(c.default || c))
export const NextNewsItems = () => import('../../components/news/NextNewsItems.vue' /* webpackChunkName: "components/next-news-items" */).then(c => wrapFunctional(c.default || c))
export const BaseOverlay = () => import('../../components/overlays/BaseOverlay.vue' /* webpackChunkName: "components/base-overlay" */).then(c => wrapFunctional(c.default || c))
export const DownloadForm = () => import('../../components/overlays/DownloadForm.vue' /* webpackChunkName: "components/download-form" */).then(c => wrapFunctional(c.default || c))
export const Empty = () => import('../../components/placeholders/Empty.vue' /* webpackChunkName: "components/empty" */).then(c => wrapFunctional(c.default || c))
export const DownloadButton = () => import('../../components/publications/DownloadButton.vue' /* webpackChunkName: "components/download-button" */).then(c => wrapFunctional(c.default || c))
export const DownloadDropdown = () => import('../../components/publications/DownloadDropdown.vue' /* webpackChunkName: "components/download-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SocialMediaSharing = () => import('../../components/social/SocialMediaSharing.vue' /* webpackChunkName: "components/social-media-sharing" */).then(c => wrapFunctional(c.default || c))
export const Careers = () => import('../../components/templates/Careers.vue' /* webpackChunkName: "components/careers" */).then(c => wrapFunctional(c.default || c))
export const GeneralPage = () => import('../../components/templates/GeneralPage.vue' /* webpackChunkName: "components/general-page" */).then(c => wrapFunctional(c.default || c))
export const ModuleTemplate = () => import('../../components/templates/ModuleTemplate.vue' /* webpackChunkName: "components/module-template" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../components/text/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const OtherThemes = () => import('../../components/themes/OtherThemes.vue' /* webpackChunkName: "components/other-themes" */).then(c => wrapFunctional(c.default || c))
export const SubTheme = () => import('../../components/themes/SubTheme.vue' /* webpackChunkName: "components/sub-theme" */).then(c => wrapFunctional(c.default || c))
export const Theme = () => import('../../components/themes/Theme.vue' /* webpackChunkName: "components/theme" */).then(c => wrapFunctional(c.default || c))
export const Tile = () => import('../../components/tiles/Tile.vue' /* webpackChunkName: "components/tile" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
